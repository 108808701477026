import './index.css';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { getRoutes } from './routes/getRoutes';
import { getSubdomain } from './routes/getSubdomain';
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";

const subdomain = getSubdomain()
const routes = getRoutes(subdomain)
const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);