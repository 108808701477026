import { useEffect } from 'react';
import CustomizedTabs from './CustomizedTabs'
import React, { useRef, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { Autocomplete, Avatar, Box, Button, Card, CircularProgress, Grid, Modal, Slide, TextField, Typography, useMediaQuery } from '@mui/material'
import { base_color_button } from '../common/global';
import CustomTabs from '../brand/CustomTabs';
import { useParams } from 'react-router-dom';
import LockedData from './LockedData';

function BrandHome(props) {

    // const user = props.user
    const { username } = useParams()
    const og_username = username

    const [success, setSuccess] = useState(false)
    const [editDetails, setEditDetails] = useState(false)
    const [copyText, setCopyText] = useState("Copy")
    const [isLoading, setIsLoading] = useState(true)

    const [profilePic, setProfilePic] = useState("")
    const [location, setLocation] = useState("")
    const [fullName, setFullName] = useState("")
    const [influencerUsername, setInfluencerUsername] = useState(og_username)
    const [newCategory, setNewCategory] = useState('')
    const [categories, setCategories] = useState([])
    const [bio, setBio] = useState("")

    const originalData = useRef({ profilePic, location, fullName, influencerUsername, categories, bio });

    const [insights, setInsights] = useState({})
    const [audienceData, setAudienceData] = useState({})
    const [pricing, setPricing] = useState({})
    const [highlightsBrands, setHighlightsBrands] = useState([]);

    async function fetchDetails() {
        console.log('fetch details called')

        // setIsLoading(true)

        let data = {
            username: og_username
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_mediakit_influencer_details', options)
            .then(result => result.json())
            .then(body => {
                // console.log("body"); console.log(body);
                if (body['success']) {
                    var homePageData = body['home']
                    // console.log('user found')
                    setSuccess(true)
                    setProfilePic(homePageData['profilePic'])
                    setLocation(homePageData['location'])
                    setFullName(homePageData['fullName'])
                    setInfluencerUsername(og_username)
                    setCategories(homePageData['categories'])
                    setBio(homePageData['bio'])

                    setPricing(body['pricing'])
                    setInsights(body['insights'])
                    setAudienceData(body['audience'])


                    setHighlightsBrands(body['insights']["topBrandsWorkedWith"])

                    originalData.current = {
                        profilePic: homePageData['profilePic'],
                        location: homePageData['location'],
                        fullName: homePageData['fullName'],
                        influencerUsername: og_username,
                        categories: homePageData['categories'],
                        bio: homePageData['bio']
                    };
                } else {
                    console.log('user not found')
                    setSuccess(false)
                }
                setIsLoading(false)
            });

    }


    useEffect(() => {
        console.log('inside use effect')
        fetchDetails()
    }, [])

    const matchesSmallScreen = useMediaQuery('(max-width: 448px) and (min-width: 360px)');
    const matchesMediumScreen = useMediaQuery('(min-width: 448px)');

    const [isBlurred, setIsBlurred] = useState(true);
    const handleSubmitDetails = () => {
        setIsBlurred(false);
    };

    console.log("isBlurred")
    console.log(isBlurred)

    if (isLoading) {
        return (
            <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: "100vh", background: "black", width: matchesSmallScreen ? '100vw' : '28rem' }}>
                <CircularProgress size={60} style={{ color: "white", height: "fit-content" }} />
            </Box>
        )
    } else {

        if (success) {
            return (
                <Box sx={{ height: "100vh", textAlign: "center", background: "white", width: matchesSmallScreen ? '100vw' : '28rem', position: "relative" }}>
                    <Box sx={{ padding: "20px 15px 10px 15px", color: "black" }}>
                        <Grid container >
                            <Grid item xs={12}>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "30%", height: "100%" }}>
                                        <Avatar sx={{ width: "90px", height: "90px" }} src={profilePic} />
                                        <Box sx={{ display: "flex", marginTop: "10px" }}>
                                            <LocationOnIcon />
                                            <Typography >{location}</Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "70%", height: "100%", textAlign: "left", }}>
                                        <Typography variant='h6' sx={{ fontWeight: "900" }}>{fullName}</Typography>
                                        <Typography variant='body1'>@{influencerUsername}</Typography>
                                        <Box sx={{ marginTop: "5px" }}>
                                            {categories.map((value, index) => (
                                                <Card key={index} sx={{ width: "fit-content", padding: "5px 20px", borderRadius: "5px", color: "white", background: base_color_button }}>{value}</Card>
                                            ))}
                                        </Box>
                                    </Box>

                                </Box>
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: "10px", padding: "0px 20px 0px 20px", textAlign: "left" }}>
                                <Typography variant="body1">{bio}</Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                <Grid container sx={{ display: "flex", marginTop: "10px", justifyContent: "space-evenly" }}>
                                    {highlightsBrands.map((value, index) => (
                                        <Grid item key={index} style={{ width: "fit-content", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "5px" }}>
                                            <Avatar sx={{ width: "60px", height: "60px", cursor: "pointer", border: "1px solid white" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/brands%2F" + value + ".jpg?alt=media"} />
                                            <Typography style={{ fontSize: "12px" }}>{value}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        {isLoading && <Box variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} ><CircularProgress size={20} sx={{ color: "black" }} /></Box>}
                        {/* {!isLoading && <CustomizedTabs username={og_username} insights={insights} pricing={pricing} audience={audienceData} />} */}
                        {!isLoading && <CustomTabs isBlurred={isBlurred} username={og_username} insights={insights} pricing={pricing} audience={audienceData} />}
                    </Box>

                    <Box sx={{ position: "fixed", bottom: 0, width: "100%" }}>
                        <LockedData influencer={og_username} onSubmitDetails={handleSubmitDetails} page="insights" />
                    </Box>
                </Box >
            )
        } else {
            return (
                <Box sx={{ height: "100vh", width: "100vw", textAlign: "center" }}>
                    <Typography>User Not Found</Typography>
                </Box >
            )
        }
    }
}

export default BrandHome