import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Divider, Grid, SvgIcon, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Pricing from './Pricing';
import Content from './Content';
import Insights from './Insights';
import AudienceDemographics from './AudienceDemographics';
import { base_color_button } from '../common/global';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';


export default function CustomTabs(props) {

    var insights = props.insights;
    var audience = props.audience;
    var pricing = props.pricing;
    var username = props.username;
    var isFacebookLogin = props.isFacebookLogin;

    var selectedStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        padding: "5px",
        borderRadius: "5px",
        width: "80%",
        height: "fit-content",
        background: base_color_button,
        color: "white"
    }

    var notSelectedStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        padding: "5px",
        borderRadius: "5px",
        width: "80%",
        height: "fit-content",
        background: "#DCDCDC",
        color: "black"
    }

    const StyledTabs = styled((props) => (
        <Tabs
            {...props}
            TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        />
    ))({
        '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        '& .MuiTabs-indicatorSpan': {
            // width: '70%',
            backgroundColor: base_color_button,
        },
    });

    const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
        ({ theme }) => ({
            textTransform: 'none',
            width: "33.33%",
            border: `2px solid ${base_color_button}`,
            borderRadius: "30px",
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.pxToRem(15),
            // marginRight: theme.spacing(1),
            color: 'rgba(255, 255, 255, 0.3)',
            '&.Mui-selected': {
                color: 'rgba(255, 255, 255, 1)',
                fontWeight: theme.typography.fontWeightBold,
            },
            '&.Mui-focusVisible': {
            },
        }),
    );

    const [tab, setTab] = useState(<Insights username={username} insights={insights} isFacebookLogin={isFacebookLogin} />);
    const [value, setValue] = useState(0);

    function handleTabChange(label) {
        if (label == 0) { setValue(0); setTab(<Insights username={username} insights={insights} isFacebookLogin={isFacebookLogin} />) }
        if (label == 1) { setValue(1); setTab(<AudienceDemographics audience={audience} isFacebookLogin={isFacebookLogin} username={username} />) }
        if (label == 2) { setValue(2); setTab(<Pricing username={username} pricing={pricing} />) }
    };


    return (
        <Grid container sx={{ background: "white" }}>
            {/* onClick={() => handleTabChange(0)} */}
            <Grid item xs={12} sx={{ marginBottom: "10px", marginTop: "10px" }}>
                <Divider variant="middle" style={{ background: 'rgb(0,0,0,0.3)' }} />
            </Grid>
            <Grid item xs onClick={() => handleTabChange(0)} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                <Box sx={value == 0 ? selectedStyle : notSelectedStyle}>
                    <VisibilityOutlinedIcon fontSize='small' />
                    <Typography sx={{ fontSize: "14px" }}>Insights</Typography>
                </Box>
            </Grid>
            <Grid item xs onClick={() => handleTabChange(1)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box sx={value == 1 ? selectedStyle : notSelectedStyle}>
                    <PersonOutlineOutlinedIcon fontSize='small' />
                    <Typography sx={{ fontSize: "14px" }}>Audience</Typography>
                </Box>
            </Grid>
            <Grid item xs onClick={() => handleTabChange(2)} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                <Box sx={value == 2 ? selectedStyle : notSelectedStyle}>
                    <LocalOfferOutlinedIcon fontSize='small' />
                    <Typography sx={{ fontSize: "14px" }}>Pricing</Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {tab}
            </Grid>
        </Grid >
    );
}