import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

function CheckingInstaConnection(props) {

  const { phone } = useParams()
  // const og_username = username

  const navigate = useNavigate();

  console.log('phone no')
  console.log(phone)

  const [isLoading, setIsLoading] = useState(true)


  async function checkDetails() {
    console.log('inside check details')

    // const token2 = await userData.getIdToken()

    let data = {
      phoneNo: phone
    }

    let options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        // 'Authorization': `Bearer ${token2}`,
        'Content-Type': "application/json"
      })
    }

    await fetch(process.env.REACT_APP_API_ADDRESS + '/api/check_mediakit_insta_settings', options)
      .then(result => result.json())
      .then(body => {

        console.log('body');
        console.log(body);

        if (body['success']) {
          setIsLoading(false)
          // console.log('details saved successfully. now navigating')
          var username = body['username']
          navigate(`/edit/${username}`)
        }
        else {
          console.log('Settings still not created')
        }
      }).catch((err) => {
        console.log('check details error');
        console.log(err);
      });
  }

  useEffect(() => {
    checkDetails()
  }, [])

  if (isLoading) {

    return (
      <Box sx={{ height: "100vh" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", justifyContent: "center", alignItems: "center", height: "100%", textAlign: "center" }}>
          <Typography variant='h5'>Fetching your Details from Instagram</Typography>
          {/* <Typography variant='h5'></Typography> */}
          <Typography variant='h5'>Creating your media kit ...</Typography>
          <GradientCircularProgress />
        </Box>
      </Box>
    )

  } else {

    return (
      <Box sx={{ height: "100vh" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", justifyContent: "center", alignItems: "center", height: "100%", textAlign: "center" }}>
          <Typography variant='h5'>Taking you to your media kit</Typography>
          <GradientCircularProgress />
        </Box>
      </Box>
    )

  }
}

export default CheckingInstaConnection


function GradientCircularProgress() {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
    </React.Fragment>
  );
}