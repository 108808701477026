import { useEffect } from 'react';
import CustomizedTabs from './CustomizedTabs'
import React, { useRef, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { Autocomplete, Avatar, Box, Button, Card, CircularProgress, Grid, Modal, Slide, TextField, Typography, useMediaQuery } from '@mui/material'
import { base_color_button } from '../common/global';
import CustomTabs from './CustomTabs';

function Home(props) {

    const user = props.user
    const og_username = props.username

    const [success, setSuccess] = useState(false)
    const [editDetails, setEditDetails] = useState(false)
    const [copyText, setCopyText] = useState("Copy")
    const [isLoading, setIsLoading] = useState(true)

    const [profilePic, setProfilePic] = useState("")
    const [location, setLocation] = useState("")
    const [fullName, setFullName] = useState("")
    const [username, setUsername] = useState(og_username)
    const [newCategory, setNewCategory] = useState('')
    const [categories, setCategories] = useState([])
    const [bio, setBio] = useState("")
    const [isFacebookLogin, setIsFacebookLogin] = useState(false)

    const originalData = useRef({ profilePic, location, fullName, username, categories, bio });

    const [insights, setInsights] = useState({})
    const [audienceData, setAudienceData] = useState({})
    const [pricing, setPricing] = useState({})

    async function fetchDetails() {
        console.log('fetch details called')

        // setIsLoading(true)

        let data = {
            username: og_username
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_mediakit_influencer_details', options)
            .then(result => result.json())
            .then(body => {
                console.log("body"); console.log(body);
                if (body['success']) {
                    var homePageData = body['home']
                    // console.log('user found')
                    setSuccess(true)
                    setProfilePic(homePageData['profilePic'])
                    setLocation(homePageData['location'])
                    setFullName(homePageData['fullName'])
                    setUsername(og_username)
                    setCategories(homePageData['categories'])
                    setIsFacebookLogin(homePageData["isFacebookLogin"]);
                    setBio(homePageData['bio'])

                    setPricing(body['pricing'])
                    setInsights(body['insights'])
                    setAudienceData(body['audience'])

                    originalData.current = {
                        profilePic: homePageData['profilePic'],
                        location: homePageData['location'],
                        fullName: homePageData['fullName'],
                        username: og_username,
                        categories: homePageData['categories'],
                        bio: homePageData['bio']
                    };
                } else {
                    console.log('user not found')
                    setSuccess(false)
                }
                setIsLoading(false)
            });

    }

    function handleCancel() {
        // Reset all states to their original values
        setProfilePic(originalData.current.profilePic);
        setLocation(originalData.current.location);
        setFullName(originalData.current.fullName);
        setUsername(originalData.current.username);
        setCategories(originalData.current.categories);
        setBio(originalData.current.bio);
        setEditDetails(false);
    }

    // console.log(categories)
    // console.log('categories')

    function handleEditCategories(value, index) {
        // const newCategories = [...categories];
        // newCategories[index] = value;
        // setCategories(newCategories);

        if (index === -1) {
            setNewCategory(value);
        } else {
            const newCategories = [...categories];
            newCategories[index] = value;
            setCategories(newCategories);
        }
    }

    // function addNewCategory() {
    //     if (newCategory.trim()) { // Only add if there's a non-empty string
    //         setCategories(oldCategories => [...oldCategories, newCategory]);
    //         setNewCategory(''); // Reset new category input after adding
    //     }
    // }


    async function saveDetails() {

        console.log('save details called')
        // localStorage.setItem('userBio', bio);
        setEditDetails(false)
        let data = {
            username: og_username,
            influencerData: {
                categories: categories,
                bio: bio
            },
        }

        console.log('data')
        console.log(data)

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/update_mediakit_influencer_details', options)
            .then(result => result.json())
            .then(body => {
                console.log("body"); console.log(body);
            });
    }

    function copyLink() {
        const linkText = `app.easycollabs.com/${og_username}`;
        navigator.clipboard.writeText(linkText).then(() => {
            setCopyText("Copied!")
            // Optionally, update copyText state to give user feedback
        }).catch(err => {
            console.error('Error copying text: ', err);
        });
    }

    useEffect(() => {
        console.log('inside use effect')
        fetchDetails()
    }, [])

    const matchesSmallScreen = useMediaQuery('(max-width: 448px) and (min-width: 360px)');
    const matchesMediumScreen = useMediaQuery('(min-width: 448px)');

    if (isLoading) {
        return (
            <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: "100vh", background: "black", width: matchesSmallScreen ? '100vw' : '28rem' }}>
                <CircularProgress size={60} style={{ color: "white", height: "fit-content" }} />
            </Box>
        )
    } else {

        if (success) {
            return (
                <Box sx={{ height: "100vh", textAlign: "center", background: "white", width: matchesSmallScreen ? '100vw' : '28rem' }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px", background: base_color_button, color: "white", padding: "5px" }}>
                        <Typography>Shareable Link: app.easycollabs.com/{og_username}</Typography>
                        <Box onClick={() => copyLink()} sx={{ width: "10%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <ContentCopyRoundedIcon fontSize='small' />
                            <Typography fontSize={14}>{copyText}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ padding: "20px 15px 10px 15px", color: "black" }}>
                        {!editDetails && <Grid container >
                            <Grid item xs={12}>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>

                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "30%", height: "100%" }}>
                                        <Avatar sx={{ width: "90px", height: "90px" }} src={profilePic} />
                                        <Box sx={{ display: "flex", marginTop: "10px" }}>
                                            <LocationOnIcon />
                                            <Typography >{location}</Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "70%", height: "100%", textAlign: "left", }}>
                                        <Typography variant='h6' sx={{ fontWeight: "900" }}>{fullName}</Typography>
                                        <Typography variant='body1'>@{username}</Typography>
                                        <Box sx={{ marginTop: "5px" }}>
                                            {categories.map((value, index) => (
                                                <Card key={index} sx={{ width: "fit-content", padding: "5px 20px", borderRadius: "5px", color: "white", background: base_color_button }}>{value}</Card>
                                            ))}
                                            {categories.length == 0 && <Typography>No Categories Added</Typography>}
                                        </Box>
                                    </Box>

                                </Box>
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: "10px", padding: "0px 20px 0px 20px", textAlign: "left" }}>
                                <Typography variant="body1">{bio}</Typography>
                            </Grid>
                        </Grid>}


                        {editDetails && <Grid container >
                            <Grid item xs={12}>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", width: "30%", height: "100%" }}>
                                        <Avatar sx={{ width: 80, height: 80 }} src={profilePic} />
                                        <Box sx={{ display: "flex" }}>
                                            <LocationOnIcon />
                                            <Typography>{location}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", width: "70%", height: "100%" }}>
                                        <Typography variant='h5' sx={{ fontWeight: "900" }}>{fullName}</Typography>
                                        <Typography>@{username}</Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", gap: "10px" }}>
                                            {/* {categories.map((value, index) => ( */}
                                            {/* key={index} */}
                                            <Box sx={{ width: "100%", border: "1px solid black" }}>
                                                <TextField
                                                    size='small'
                                                    // key={index}
                                                    placeholder='Enter a Category'
                                                    sx={{ background: "white" }}
                                                    value={categories[0] || ''}
                                                    onChange={(e) => handleEditCategories(e.target.value, 0)} />
                                            </Box>
                                            {/* ))} */}

                                            {/* {categories.length < 3 &&
                                            <Grid container sx={{ gap: "10px" }}>
                                                {<Grid item sx={{ width: "fit-content" }}>
                                                    <TextField
                                                        size='small'
                                                        sx={{ background: "#DCDCDC" }}
                                                        value={newCategory}
                                                        // onBlur={addNewCategory}
                                                        onChange={(e) => handleEditCategories(e.target.value, -1)} />
                                                </Grid>}
                                            </Grid>} */}
                                            {/* {Array.from({ length: 3 - categories.length }, (_, i) => (
                                            <Grid key={i} item xs={4} sx={{ width: "fit-content" }}>
                                                <TextField
                                                    size='small'
                                                    sx={{ background: "#DCDCDC" }}
                                                    value={newCategory}
                                                    placeholder="Add new category"
                                                    onChange={(e) => handleEditCategories(e.target.value, -1)}
                                                    onBlur={() => {
                                                        if (newCategory.trim()) {
                                                            setCategories([...categories, newCategory]);
                                                            setNewCategory('');
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        ))} */}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: "10px", marginBottom: "10px", background: "white" }}>
                                <TextField
                                    sx={{ width: "100%" }}
                                    // label='Enter Bio'
                                    placeholder='Enter Bio'
                                    value={bio}
                                    multiline
                                    rows={4}
                                    inputProps={{
                                        maxLength: 170
                                    }}
                                    onChange={(e) => setBio(e.target.value)}
                                />
                            </Grid>
                        </Grid>}

                        {!editDetails && <Button size='small' sx={{ background: base_color_button, color: "white" }} onClick={() => setEditDetails(true)}>Edit Bio & Category</Button>}
                        {editDetails &&
                            <Box sx={{ gap: "20px", display: "flex", justifyContent: "center" }}>
                                <Button sx={{ background: base_color_button, color: "white" }} onClick={() => saveDetails()}>Save</Button>
                                <Button sx={{ background: base_color_button, color: "white" }} onClick={() => handleCancel()}>Cancel</Button>
                            </Box>
                        }
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {isLoading && <Box variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} ><CircularProgress size={20} sx={{ color: "black" }} /></Box>}
                        {/* {!isLoading && <CustomizedTabs username={og_username} insights={insights} pricing={pricing} audience={audienceData} />} */}
                        {!isLoading && <CustomTabs isFacebookLogin={isFacebookLogin} username={og_username} insights={insights} pricing={pricing} audience={audienceData} />}
                    </Box>
                </Box >
            )
        } else {
            return (
                <Box sx={{ height: "100vh", width: "100vw", textAlign: "center" }}>
                    <Typography>User Not Found</Typography>
                </Box >
            )
        }
    }
}

export default Home