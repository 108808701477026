// import '../index.css';
import Home from "../components/influencer/Home.jsx";
import Login from "../components/influencer/Login";
import Signup from "../components/influencer/Signup";
// import Signin from "../components/influencer/Signin";

import { Navigate } from "react-router-dom";
import Register from "../components/influencer/Register";
import BrandHome from "../components/brand/BrandHome";
import Navigation from "../components/common/Navigation.jsx";
import CheckingInstaConnection from "../components/influencer/CheckingInstaConnection.jsx";

export const getRoutes = (subdomain) => {

    // console.log("subdomain inside routes")
    // console.log(subdomain)


    let routes = [
        {
            path: "/",
            element: <Navigation source={"login"} />,
            // element: <Navigate to="/login" replace={true} />,
        },
        {
            path: "/login",
            // element: <Navigation source={"login"} />,
            element: <Login />,
        },
        {
            path: "/register",
            element: <Navigation source={'register'} />,
            // element: <Register />,
        },
        {
            path: "/edit/:username",
            element: <Navigation source={'home'} />,
            // element: <Home />,
        },
        {
            path: "/:username",
            // element: <Navigation source={'brandHome'} />,
            element: <BrandHome />,
        },
        {
            path: "/checkingInstaConnection/:phone",
            // element: <Navigation source={'brandHome'} />,
            element: <CheckingInstaConnection />,
        },
    ]

    return routes;
}