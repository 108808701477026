import React, { useState } from 'react'
import Logo from "../../assets/logo192.png"
import StarIcon from '@mui/icons-material/Star';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Box, Button, CircularProgress, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Step, StepLabel, Stepper, TextField, Typography, useMediaQuery } from '@mui/material'

import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from '../../api/firebase.js';
import InstagramLogin from './InstagramLogin.jsx';
import VerticalLinearStepper from './InstagramSteps.jsx';
// import MediaKitLogo from "../../assets/mediaKitLogo.png"
// import MediaKitLogo from "../../assets/mediaKitLogoRemovebg.png"
import MediaKitLogo from "../../assets/Influencer Kit_WhiteLogo.png"
import { base_color_button } from '../common/global.js';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useNavigate } from 'react-router-dom';


const steps = ['Enter Your Details', 'Enter Your Content Price', 'Connect Your Instagram'];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: 'black',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: 'black',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: 'black',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: 'black',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

function Register(props) {

    const user = props.user;
    // console.log('register user')
    // console.log(props)
    // console.log(user)

    const [username, setUsername] = useState("")
    const [fullName, setFullName] = useState("")
    const [age, setAge] = useState("")
    const [email, setEmail] = useState("")
    const [gender, setGender] = useState('Female');
    const [city, setCity] = useState("");

    const [reelPrice, setReelPrice] = useState('');
    const [postPrice, setPostPrice] = useState('');
    const [storyPrice, setStoryPrice] = useState('');
    const [ugcPrice, setUgcPrice] = useState('');

    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [fullNameError, setFullNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [genderError, setGenderError] = useState(false)

    const [instagramSignup, setInstagramSignup] = useState(false);

    // const [user, setUser] = useState(null)
    const [otp, setOtp] = useState("")
    const [verifyOtpButton, setVerifyOtpButton] = useState(false)

    const handleFullName = event => {
        setFullName(event.target.value);
        setFullNameError(false)
    };
    const handleEmail = event => {
        setEmail(event.target.value);
        setEmailError(false)
    };

    const handleGender = (event) => {
        setGender(event.target.value);
        setGenderError(false)
    };

    const handleAge = event => {
        setAge(event.target.value);
        // setEmailError(false)
    };

    const handleCity = event => {
        setCity(event.target.value);
        // setEmailError(false)
    };

    function handleSubmitForm() {
        console.log("form submitted")

        // api call for saving these details
    }


    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const isStepOptional = (step) => {
        return step === 3;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

        console.log('active step')
        console.log(activeStep)

        if (activeStep == 1) {
            saveDetails()
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    async function saveDetails() {

        console.log('save details called')
        // localStorage.setItem('userBio', bio);
        let data = {
            phoneNo: user.phoneNumber,
            influencerData: {
                fullName: fullName,
                email: email,
                age: age,
                gender: gender,
                location: city,
                pricing: {
                    reel: {
                        description: '',
                        price: reelPrice,
                        quantity: 1
                    },
                    post: {
                        description: '',
                        price: postPrice,
                        quantity: 1
                    },
                    story: {
                        description: '',
                        price: storyPrice,
                        quantity: 1
                    },
                    ugc: {
                        description: '',
                        price: ugcPrice,
                        quantity: 1
                    }
                }
            },
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/update_mediakit_influencer_details', options)
            .then(result => result.json())
            .then(body => {
                console.log("body"); console.log(body);
            });
    }

    const navigate = useNavigate();

    function handleInstaConnect() {
        navigate('/edit/snitch.co.in')
    }

    const matchesSmallScreen = useMediaQuery('(max-width: 448px) and (min-width: 360px)');
    const matchesMediumScreen = useMediaQuery('(min-width: 448px)');




    return (
        <Box sx={{ background: base_color_button, width: matchesSmallScreen ? '100vw' : '28rem', height: "100vh" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", }}>
                <img
                    src={MediaKitLogo}
                    alt=''
                    width="304px"
                    height="65px"
                    style={{ marginTop: "10px" }}
                />
            </Box>
            <Box sx={{ width: '100%', marginTop: "20px", background: base_color_button, }}>
                <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps} sx={{ color: "white" }}>
                                <StepLabel StepIconComponent={QontoStepIcon} {...labelProps} >{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <React.Fragment>
                    {activeStep == 0 &&
                        <Box sx={{ height: "100%" }}>
                            {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}

                            <Box sx={{ display: "flex", justifyContent: "center", height: "auto" }}>
                                <Box style={{ padding: "20px", height: "auto", borderRadius: "25px", zIndex: 99, display: "flex", flexDirection: "column" }}>
                                    {/* <Typography>Connect Instagram Account</Typography> */}
                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "auto", marginBottom: "20px", padding: "15px", borderRadius: "10px", gap: "10px" }}>
                                        <Typography variant="h4" sx={{ color: "white", textAlign: "center", fontWeight: "900" }}>Create Your Own Personal Media Kit</Typography>
                                        <Typography sx={{ color: "white", textAlign: "center" }}>Enter your details below to start creating your media kit</Typography>
                                    </Box>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                                <Typography>Full Name</Typography>
                                                <StarIcon sx={{ height: "10px", color: "red" }} />
                                            </Box>
                                            <TextField
                                                error={fullNameError}
                                                helperText={fullNameError ? "Please enter full name" : ""}
                                                value={fullName} onChange={handleFullName} sx={{ width: "100%" }} placeholder="Enter your Full Name" variant="standard" />
                                        </Grid>
                                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                                <Typography>Email</Typography>
                                                <StarIcon sx={{ height: "10px", color: "red" }} />
                                            </Box>
                                            <TextField
                                                error={emailError}
                                                helperText={emailError ? "Please enter valid email" : ""}
                                                value={email} onChange={handleEmail} sx={{ width: "100%" }} placeholder="Enter your email" variant="standard" />
                                        </Grid>
                                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                                <Typography>Age</Typography>
                                                <StarIcon sx={{ height: "10px", color: "red" }} />
                                            </Box>
                                            <TextField type="number" sx={{ width: "100%" }} label="Enter your age" value={age} onChange={handleAge} variant="standard" />
                                        </Grid>
                                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                                <Typography>Gender</Typography>
                                                <StarIcon sx={{ height: "10px", color: "red" }} />
                                            </Box>
                                            <FormControl sx={{ width: "100%" }}>
                                                <RadioGroup
                                                    value={gender}
                                                    onChange={handleGender}
                                                >
                                                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                                <Typography>City</Typography>
                                                <StarIcon sx={{ height: "10px", color: "red" }} />
                                            </Box>
                                            <TextField
                                                sx={{ width: "100%" }}
                                                label="Enter your city"
                                                value={city} onChange={handleCity}
                                                variant="standard" />
                                        </Grid>
                                    </Grid>
                                    {/* {true && <Button onClick={() => handleSubmitForm()} sx={{ background: "black", color: "white", borderRadius: "5px", marginTop: "5px" }}>SUBMIT</Button>} */}
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Button sx={{ color: "white", background: ((fullName.length != 0 && email.length != 0 && age.length != 0 && city.length != 0) ? "black" : "#DCDCDC"), width: "60%", marginBottom: "10px" }} disabled={fullName.length == 0 && email.length == 0 && age.length == 0 && city.length == 0} onClick={handleNext}>Next</Button>
                            </Box>
                        </Box>}
                    {activeStep == 1 &&
                        <Box sx={{ height: "100%" }}>
                            {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}

                            <Box sx={{ display: "flex", justifyContent: "center", height: "auto" }}>
                                <Box style={{ padding: "20px", height: "auto", borderRadius: "25px", zIndex: 99, display: "flex", flexDirection: "column" }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "auto", marginBottom: "20px", padding: "15px", borderRadius: "10px", gap: "10px" }}>
                                        <Typography sx={{ color: "black", textAlign: "center" }}>Enter your price</Typography>
                                    </Box>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                                <Typography>Enter 1 Reel Price</Typography>
                                                <StarIcon sx={{ height: "10px", color: "red" }} />
                                            </Box>
                                            <TextField
                                                // error={usernameError}
                                                // helperText={usernameError ? "Please price" : ""}
                                                value={reelPrice} type='number' onChange={(e) => setReelPrice(e.target.value)} sx={{ width: "100%" }} placeholder="Enter price" variant="standard" />
                                        </Grid>

                                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                                <Typography>Enter 1 Post Price</Typography>
                                                <StarIcon sx={{ height: "10px", color: "red" }} />
                                            </Box>
                                            <TextField
                                                // error={usernameError}
                                                // helperText={usernameError ? "Please price" : ""}
                                                value={postPrice} type='number' onChange={(e) => setPostPrice(e.target.value)} sx={{ width: "100%" }} placeholder="Enter price" variant="standard" />
                                        </Grid>

                                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                                <Typography>Enter 1 Story Price</Typography>
                                                <StarIcon sx={{ height: "10px", color: "red" }} />
                                            </Box>
                                            <TextField
                                                // error={usernameError}
                                                // helperText={usernameError ? "Please price" : ""}
                                                value={storyPrice} type='number' onChange={(e) => setStoryPrice(e.target.value)} sx={{ width: "100%" }} placeholder="Enter price" variant="standard" />
                                        </Grid>

                                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                                <Typography>Enter 1 UGC Price</Typography>
                                                <StarIcon sx={{ height: "10px", color: "red" }} />
                                            </Box>
                                            <TextField
                                                // error={usernameError}
                                                // helperText={usernameError ? "Please price" : ""}
                                                value={ugcPrice} type='number' onChange={(e) => setUgcPrice(e.target.value)} sx={{ width: "100%" }} placeholder="Enter price" variant="standard" />
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Button sx={{ color: "white", background: ((reelPrice.length != 0 && postPrice.length != 0 && storyPrice.length != 0 && ugcPrice.length != 0) ? "black" : "#DCDCDC"), width: "60%", marginBottom: "10px" }} disabled={reelPrice.length.length == 0 && postPrice.length == 0 && storyPrice.length == 0 && ugcPrice.length == 0} onClick={handleNext}>Next</Button>
                            </Box>
                        </Box>}
                    {activeStep == 2 && <Box sx={{ height: "100%", marginTop: "30px" }}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <VerticalLinearStepper />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                            <InstagramLogin userData={user} phone={user.phoneNumber} />
                        </Box>
                    </Box>}

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: "center" }}>
                        {/* {activeStep != 2 && <Button sx={{ color: "white", background: ((fullName.length != 0 && email.length != 0 && age.length != 0 && city.length != 0) ? base_color_button : "#DCDCDC"), width: "60%", marginBottom: "10px" }} disabled={fullName.length == 0 && email.length == 0 && age.length == 0 && city.length == 0} onClick={handleNext}>Next</Button>} */}
                        {/* {activeStep == 1 && <Button onClick={handleNext}>Next</Button>} */}
                        {/* <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button> */}
                    </Box>
                </React.Fragment>
            </Box >
        </Box >
    )
}

export default Register