import React, { useEffect, useState } from 'react'
import LockIcon from '@mui/icons-material/Lock';
import { base_color_button } from '../common/global';
import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material'
import LockedData from './LockedData';

function Pricing(props) {

    var contentPricing = props.pricing.pricing;
    var username = props.username;
    var isBlurred = props.isBlurred;


    const [pricing, setPricing] = useState(() => {
        return {
            "reel": { ...contentPricing.reel, price: contentPricing.reel.price },
            "post": { ...contentPricing.post, price: contentPricing.post.price },
            "story": { ...contentPricing.story, price: contentPricing.story.price },
            "ugc": { ...contentPricing.ugc, price: contentPricing.ugc.price }
        };
    });


    function formatNumber(number) {
        if (number >= 100000) {
            return (number / 100000).toFixed(0) + "L";
        }
        else if (number >= 1000) {
            return (number / 1000).toFixed(0) + "K";
        } else {
            return number;
        }
    }





    var evenStyle = { display: "flex", justifyContent: "right", paddingRight: "10px" }
    var oddStyle = { display: "flex", justifyContent: "left", paddingLeft: "10px" }

    const getItemStyle = (index, evenStyle, oddStyle) => ({
        ...index % 2 === 0 ? evenStyle : oddStyle,
        ...(index <= 1 ? { paddingBottom: "10px" } : { paddingTop: "10px" }),
    });

    useEffect(() => {
        if (isBlurred) {
            document.body.style.overflow = 'hidden'; // Disable scrolling
        } else {
            document.body.style.overflow = 'auto'; // Enable scrolling
        }
    }, [isBlurred]);

    return (
        <Box sx={{ position: "relative" }}>

            <Box
                id="main-content"
                sx={{
                    marginTop: "25px",
                    background: "white",
                    color: "black",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    filter: isBlurred ? 'blur(10px)' : 'none', // Add blur effect
                    pointerEvents: isBlurred ? 'none' : 'auto', // Disable interaction when blurred
                }}
            >

                <Box sx={{ border: "1px solid black", padding: "5px 15px 5px 15px", marginBottom: "10px", marginTop: "10px", borderRadius: "8px" }}>
                    <Typography>Instagram</Typography>
                </Box>

                <Grid container sx={{ marginTop: "15px" }}>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "right", paddingRight: "10px", paddingBottom: "10px" }}>
                        <Box sx={{ background: "#F1EEF9", gap: "10px", borderRadius: "15px", width: "50%", height: "60%", padding: "20px 10px", marginRight: "10px", marginBottom: "10px" }}>
                            <Box>
                                <Typography sx={{ fontWeight: "900", fontSize: "30px" }}>₹ {formatNumber(pricing.reel.price)}</Typography>
                            </Box>
                            <Box>
                                <Typography variant='caption'>1 Reel</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "left", paddingLeft: "10px", paddingBottom: "10px" }}>
                        <Box sx={{ background: "#F1EEF9", gap: "10px", borderRadius: "15px", width: "50%", height: "60%", padding: "20px 10px", marginLeft: "10px", marginBottom: "10px" }}>
                            <Box>
                                <Typography sx={{ fontWeight: "900", fontSize: "30px" }}>₹ {formatNumber(pricing.post.price)}</Typography>
                            </Box>
                            <Box>
                                <Typography variant='caption'>1 Post</Typography>
                            </Box>
                        </Box>
                    </Grid>


                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "right", paddingRight: "10px", paddingTop: "10px" }}>
                        <Box sx={{ background: "#F1EEF9", gap: "10px", borderRadius: "15px", width: "50%", height: "60%", padding: "20px 10px", marginRight: "10px", marginTop: "10px" }}>
                            <Box>
                                <Typography sx={{ fontWeight: "900", fontSize: "30px" }}>₹ {formatNumber(pricing.story.price)}</Typography>
                            </Box>
                            <Box>
                                <Typography variant='caption'>1 Story</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "left", paddingLeft: "10px", paddingTop: "10px" }}>
                        <Box sx={{ background: "#F1EEF9", gap: "10px", borderRadius: "15px", width: "50%", height: "60%", padding: "20px 10px", marginLeft: "10px", marginTop: "10px" }}>
                            <Box>
                                <Typography sx={{ fontWeight: "900", fontSize: "30px" }}>₹ {formatNumber(pricing.ugc.price)}</Typography>
                            </Box>
                            <Box>
                                <Typography variant='caption'>1 UGC</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {isBlurred && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: '60%',
                        left: '50%',
                        transform: 'translate(-50%, -20%)',
                        zIndex: 1300,
                    }}
                >
                    {/* <LockedData page="pricing" /> */}
                    <LockIcon fontSize='large' />
                    <Typography>Please send a collab request to unlock pricing</Typography>
                </Box>
            )}


        </Box>
    )
}

export default Pricing