import LockedData from './LockedData';
import PieCharts from '../common/PieCharts'
import LockIcon from '@mui/icons-material/Lock';
import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Divider, Grid, LinearProgress, Typography } from '@mui/material'

function AudienceDemographics(props) {

    const audience = props.audience;

    var audienceAge = audience.audienceAge
    var audienceCity = audience.audienceCity
    var audienceGender = audience.audienceGender
    var isBlurred = props.isBlurred;

    // CITY
    // var totalCityCount = 100;
    // var cityDist = Object.values(audienceCity);
    // var cityLabels = Object.keys(audienceCity);
    // var finalCityDist = cityDist.map(element => (element / totalCityCount) * 100);
    const sortedCities = Object.entries(audienceCity)
        .sort((a, b) => b[1] - a[1]) // Sort the array by value in descending order
        .slice(0, 3); // Get the top three items

    // GENDER
    var genderDistribution = Object.values(audienceGender);
    var genderLabels = Object.keys(audienceGender);

    // AGE
    // var ageDistribution = Object.values(audienceAge).slice(0, 4);
    // var ageLabels = Object.keys(audienceAge).slice(0, 4);

    const sortedAges = Object.entries(audienceAge)
        .sort((a, b) => b[1] - a[1]) // Sort the array by value in descending order
        .slice(0, 2); // Get the top two items

    // Calculate the sum of the top two values
    const topTwoSum = sortedAges.reduce((acc, item) => acc + item[1], 0);

    // Calculate the "Others" category value
    const others = 100 - topTwoSum;

    // Include "Others" in the results
    const finalSortedAges = [
        ...sortedAges,
        ["Others", others]
    ];

    var ageLabels = finalSortedAges.map(item => item[0]);
    var ageDistribution = finalSortedAges.map(item => item[1]);

    useEffect(() => {
        if (isBlurred) {
            document.body.style.overflow = 'hidden'; // Disable scrolling
        } else {
            document.body.style.overflow = 'auto'; // Enable scrolling
        }
    }, [isBlurred]);




    return (
        <Box sx={{ position: 'relative' }}>
            <Box
                id="main-content"
                sx={{
                    marginTop: "25px",
                    marginBottom: "30px",
                    background: "white",
                    color: "black",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    filter: isBlurred ? 'blur(10px)' : 'none', // Add blur effect
                    pointerEvents: isBlurred ? 'none' : 'auto', // Disable interaction when blurred
                }}
            >
                <Box
                    sx={{
                        border: "1px solid black",
                        padding: "5px 15px",
                        margin: "20px 0 10px",
                        borderRadius: "8px"
                    }}
                >
                    <Typography>City Distribution</Typography>
                </Box>

                <Grid container sx={{ marginBottom: "20px", padding: "15px" }}>
                    {sortedCities.map((value, index) => (
                        <Grid item xs={4} key={index} sx={{ display: "flex", justifyContent: "center" }}>
                            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", color: "black" }}>
                                <Typography variant='h4' sx={{ fontWeight: "900" }}>{value[1]}%</Typography>
                                <Typography>{value[0]}</Typography>
                            </Box>
                        </Grid>
                    ))}

                    <Grid item xs sx={{ marginTop: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid black", padding: "5px 15px", margin: "20px 0 10px", borderRadius: "8px" }}>
                            <Typography>Gender Distribution</Typography>
                        </Box>
                        <Grid item sx={{ width: "100%", height: "100%" }}>
                            <PieCharts labels={genderLabels} distribution={genderDistribution} type="gender" />
                        </Grid>
                    </Grid>

                    <Grid item xs sx={{ marginTop: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid black", padding: "5px 15px", margin: "20px 0 10px", borderRadius: "8px" }}>
                            <Typography>Age Distribution</Typography>
                        </Box>
                        <Grid item sx={{ width: "100%", height: "100%" }}>
                            <PieCharts labels={ageLabels} distribution={ageDistribution} type="age" />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            {isBlurred && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: '60%',
                        left: '50%',
                        transform: 'translate(-50%, -20%)',
                        zIndex: 1300,
                    }}
                >
                    {/* <LockedData page="audience" /> */}
                    <LockIcon fontSize='large' />
                    <Typography>Please send a collab request to unlock audience insights</Typography>
                </Box>
            )}
        </Box>
    )
}

export default AudienceDemographics