import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Divider, Grid, SvgIcon, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Pricing from './Pricing';
import Insights from './Insights';
import AudienceDemographics from './AudienceDemographics';
import { base_color_button } from '../common/global';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';


export default function CustomTabs(props) {

    var pricing = props.pricing;
    var audience = props.audience;
    var insights = props.insights;
    var username = props.username;
    var isBlurred = props.isBlurred;

    var selectedStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        padding: "5px",
        borderRadius: "5px",
        width: "80%",
        height: "fit-content",
        background: base_color_button,
        color: "white"
    }

    var notSelectedStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        padding: "5px",
        borderRadius: "5px",
        width: "80%",
        height: "fit-content",
        // background: "#DCDCDC",
        background: "#F1EEF9",
        color: "black"
    }

    const StyledTabs = styled((props) => (
        <Tabs
            {...props}
            TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        />
    ))({
        '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        '& .MuiTabs-indicatorSpan': {
            // width: '70%',
            backgroundColor: base_color_button,
        },
    });

    const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
        ({ theme }) => ({
            textTransform: 'none',
            width: "33.33%",
            border: `2px solid ${base_color_button}`,
            borderRadius: "30px",
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.pxToRem(15),
            // marginRight: theme.spacing(1),
            color: 'rgba(255, 255, 255, 0.3)',
            '&.Mui-selected': {
                color: 'rgba(255, 255, 255, 1)',
                fontWeight: theme.typography.fontWeightBold,
            },
            '&.Mui-focusVisible': {
            },
        }),
    );

    const [tab, setTab] = useState(<Insights username={username} insights={insights} />);
    const [value, setValue] = useState(0);

    // function handleTabChange(label) {
    //     if (label == 0) { setValue(0); setTab(<Insights username={username} insights={insights} />) }
    //     if (label == 1) { setValue(1); setTab(<AudienceDemographics isBlurred={isBlurred} audience={audience} />) }
    //     if (label == 2) { setValue(2); setTab(<Pricing isBlurred={isBlurred} username={username} pricing={pricing} />) }
    // };

    useEffect(() => {
        if (value === 0) {
            setTab(<Insights username={username} insights={insights} />);
        } else if (value === 1) {
            setTab(<AudienceDemographics isBlurred={isBlurred} audience={audience} />);
        } else if (value === 2) {
            setTab(<Pricing isBlurred={isBlurred} username={username} pricing={pricing} />);
        }
    }, [isBlurred, value]);

    const handleTabChange = (label) => {
        setValue(label);
    };


    return (
        <Grid container sx={{ background: "white", display: "flex", justifyContent: "center" }}>
            {/* onClick={() => handleTabChange(0)} */}
            <Grid item xs={11} sx={{ marginBottom: "10px", marginTop: "10px" }}>
                <Divider variant="middle" style={{ background: 'rgb(0,0,0,0.3)' }} />
            </Grid>
            <Grid item xs={3.5} onClick={() => handleTabChange(0)} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                <Box sx={value == 0 ? selectedStyle : notSelectedStyle}>
                    <VisibilityOutlinedIcon fontSize='small' />
                    <Typography sx={{ fontSize: "14px" }}>Insights</Typography>
                </Box>
            </Grid>
            <Grid item xs={3.5} onClick={() => handleTabChange(1)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box sx={value == 1 ? selectedStyle : notSelectedStyle}>
                    <PersonOutlineOutlinedIcon fontSize='small' />
                    <Typography sx={{ fontSize: "14px" }}>Audience</Typography>
                </Box>
            </Grid>
            <Grid item xs={3.5} onClick={() => handleTabChange(2)} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                <Box sx={value == 2 ? selectedStyle : notSelectedStyle}>
                    <LocalOfferOutlinedIcon fontSize='small' />
                    <Typography sx={{ fontSize: "14px" }}>Pricing</Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {tab}
            </Grid>
        </Grid >
    );
}