import React, { useEffect, useState } from 'react'
import Logo from "../../assets/logo192.png"
import StarIcon from '@mui/icons-material/Star';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import { Box, Button, CircularProgress, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography, useMediaQuery } from '@mui/material'

import { getAuth, onAuthStateChanged, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from '../../api/firebase.js';
import InstagramLogin from './InstagramLogin.jsx';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Skeleton from '@mui/material/Skeleton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Navigate, useNavigate } from 'react-router-dom';

import OtpInput from "otp-input-react";

// import MediaKitLogo from "../../assets/mediaKitLogoRemovebg.png"
import MediaKitLogo from "../../assets/Influencer Kit_WhiteLogo.png"
import { base_color_button } from '../common/global.js';


// check using cloud function if user already exists or not
// const registeredUser = auth.getUserByPhoneNumber(phone)
// console.log("registeredUser")
// console.log(registeredUser)

const drawerBleeding = 46;

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

function Login() {

    // const { window } = props;
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    // This is used only for the example
    // const container = window !== undefined ? () => window().document.body : undefined;

    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [gender, setGender] = useState('female');
    const [isLoading, setIsLoading] = useState(false)



    const [confirmation, setConfirmation] = useState(null)
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [user, setUser] = useState(null)
    const [otp, setOtp] = useState("")
    const [verifyOtpButton, setVerifyOtpButton] = useState(false)

    const [firstLogin, setFirstLogin] = useState(false)



    // auth.useDeviceLanguage();

    async function onCaptchVerify() {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                auth,
                "recaptcha",
                {
                    size: "invisible",
                    callback: (response) => {
                        sendOtp();
                    },
                    "expired-callback": () => console.log('timeout ho gya'),
                },
            );
            window.recaptchaVerifier.render().then(function (widgetId) {
                window.recaptchaWidgetId = widgetId;
            });
            await window.recaptchaVerifier.render();
        }
    }


    async function sendOtp() {
        console.log('inside send otp')
        setIsLoading(true);
        onCaptchVerify();

        const appVerifier = window.recaptchaVerifier;

        const formatPh = "+" + phone;

        console.log('starting check')
        setVerifyOtpButton(true);
        signInWithPhoneNumber(auth, formatPh, appVerifier)
            .then((confirmationResult) => {
                console.log('confirmation')
                window.confirmationResult = confirmationResult;
                setIsLoading(false);
                // setVerifyOtpButton(true);
                // toast.success("OTP send successfully!");
            })
            .catch((error) => {
                console.log('sendOTP error');
                console.log(error);
                setIsLoading(false);
            });
    }



    async function verifyOtp() {

        setIsLoading(true);
        window.confirmationResult
            .confirm(otp)
            .then(async (res) => {
                console.log('res');
                // console.log(res);
                setUser(res.user);
                await savePhoneNumberToDb()
                console.log('navigating to register')
                navigate('/register')
                setIsLoading(false);
            })
            .catch((err) => {
                console.log('err');
                console.log(err);
                setIsLoading(false);
            });
    }

    async function savePhoneNumberToDb() {

        // console.log('save phone called')
        let data = {
            phoneNo: phone,
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/save_phone_number', options)
            .then(result => result.json())
            .then(body => {
                // console.log("body"); console.log(body);
            });
    }

    useEffect(() => {
        if (otp.length == 6) {
            console.log('otp set')
            verifyOtp()
        }
    }, [otp])

    // useEffect(() => {
    //     console.log('inside use effect login')
    //     console.log(firstLogin)
    //     onAuthStateChanged(auth, (user) => {
    //         if (user) {
    //             // User is signed in, see docs for a list of available properties
    //             // https://firebase.google.com/docs/reference/js/auth.user
    //             const uid = user.uid;
    //             // console.log("USER OBJ")
    //             // console.log(user)

    //             console.log('firstLogin inside onAuthStateChanged')
    //             console.log(firstLogin)

    //             if (!firstLogin) {
    //                 console.log('user exists. navigating inside')
    //                 navigate("/register")
    //             }
    //             else {
    //                 console.log('user logging in for first time. case handled already')
    //             }
    //         } else {
    //             console.log('no user found')
    //             setFirstLogin(true)
    //         }
    //     });
    // }, [])

    const matchesSmallScreen = useMediaQuery('(max-width: 448px) and (min-width: 360px)');

    return (
        <Box sx={{ background: base_color_button, width: matchesSmallScreen ? '100vw' : '28rem', height: "100vh", minHeight: "832px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "80px" }}>
            <Box>
                <img
                    src={MediaKitLogo}
                    alt=''
                    width="304px"
                    height="65px"
                />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", background: "white", border: "1px solid black", borderRadius: "10px", width: "90%" }}>
                {!verifyOtpButton &&
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "25px" }}>
                        <Typography variant='h5' sx={{ color: "black", marginBottom: "10px", fontWeight: "1000" }}>Create your Account</Typography>
                        <Typography variant='subtitle2' sx={{ color: "#666666", marginBottom: "20px" }}>Enter your mobile number to continue</Typography>
                        <Box>
                            <PhoneInput
                                size
                                country={'in'}
                                value={phone}
                                onChange={(phone) => setPhone("+" + phone)} />
                        </Box>
                        <Button sx={{ padding: "10px", marginTop: "20px", width: "100%", borderRadius: "10px", background: (phone.length != 13 ? "#DCDCDC" : "#5F65EA"), color: "white" }} disabled={phone.length != 13} onClick={() => sendOtp()}>Get OTP</Button>
                    </Box>}

                {verifyOtpButton && <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "25px" }}>
                    <Box sx={{ textAlign: 'left', width: "100%", marginLeft: "-20px", marginTop: "-10px", marginBottom: "30px" }}>
                        <Typography sx={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "5px" }} onClick={() => setVerifyOtpButton(false)}><ArrowBackIcon />Back</Typography>
                    </Box>
                    <Box sx={{ width: "90%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "fit-content" }}>
                        <Typography variant='h5' sx={{ color: "black", marginBottom: "20px" }}>We've sent you an OTP</Typography>
                        <Box sx={{ width: "80%" }}>
                            <Typography variant='body1' sx={{ color: "#666666", marginBottom: "20px", textAlign: "center" }}>Please enter the 6 digit otp sent to you at {phone}</Typography>
                        </Box>
                        {isLoading && <CircularProgress size={30} style={{ color: "black", height: "fit-content" }} />}
                        {!isLoading && <Box>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                OTPLength={6}
                                otpType="number"
                                disabled={false}
                                autoFocus
                                inputStyles={{ width: "25px", height: "25px" }}
                            />
                        </Box>
                        }
                    </Box>
                    {/* <Button onClick={() => verifyOtp()} sx={{ marginTop: "10px" }} variant='contained' color='success'>Verify OTP</Button> */}
                </Box>}
            </Box>
            <div id="recaptcha"></div>
        </Box >
    )
}

export default Login