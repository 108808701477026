import { Alert, AlertTitle, Avatar, Box, Button, CircularProgress, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import FacebookLogin from "react-facebook-login";
import instagramLogo from "../../assets/instagramLogo.png"
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';


function InstagramLogin(props) {

    const navigate = useNavigate();

    const userData = props.userData;
    const phone = props.phone;

    const [isLoading, setIsLoading] = useState(false)
    const [instaSettings, setInstaSettings] = useState(false)
    const [isInstagramActive, setIsInstagramActive] = useState(false)
    const [instagramLoginError, setInstagramLoginError] = useState(false)
    const [instagramLoginErrorMsg, setInstagramLoginErrorMsg] = useState("")
    const [usernameInput, setUsernameInput] = useState("")
    const [usernameInputLoading, setUsernameInputLoading] = useState(false)

    const [caption, setCaption] = useState("")
    const [followers, setFollowers] = useState(0)
    const [profilePic, setProfilePic] = useState("")
    const [displayInfluencerDetails, setDisplayInfluencerDetails] = useState(false)
    const [usernameInputError, setUsernameInputError] = useState("")
    const [submitButtonError, setSubmitButtonError] = useState("")
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false)





    async function enrichMediaKitData(username) {

        console.log('starting enrich media')
        console.log(username)
        setDisplayInfluencerDetails(false)

        // setIsLoading(true)
        // const token2 = await userData.getIdToken()

        let data = {
            username: username
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/enrich_mediakit_instagram_data', options)
            .then(result => result.json())
            .then(body => {

                console.log('enrich body');
                console.log(body);

                if (body == "Success") {
                    setIsInstagramActive(true);
                    setIsLoading(false)
                    console.log('details saved successfully. now navigating')
                    navigate(`/edit/${username}`)
                }
                else {
                    console.log('error while enriching data')
                }
            }).catch((err) => {
                console.log('enrich media error');
                console.log(err);
                setInstagramLoginError(true)
                setInstagramLoginErrorMsg("Unable to fetch details. Please contact Closer team.")
                setIsLoading(false);
            });
    }

    // useEffect(() => {
    //     checkActiveInstaUsernameConnection()
    // }, [])

    function handleLogout() {
        console.log("LOGOUT SUCCESSFULL")
    }

    function handleSubscribe(fbPageId, pageAccessToken) {
        // console.log("ENTERED SUBSCRIPTION");

        window.FB.api(
            "/" + fbPageId + "/subscribed_apps?access_token=" + pageAccessToken,
            "POST",
            { subscribed_fields: "mention,messages" },
            function (response) {
                // console.log("Subscribe called");
                console.log(response);
            }
        );
    }

    async function responseFacebook(response) {
        console.log("Response access token");
        console.log(response);
        console.log(response.accessToken);
        console.log(phone);

        setIsLoading(true)
        setInstagramLoginError(false)
        setInstagramLoginErrorMsg("")

        // create instagram settings
        // const token2 = await userData.getIdToken();

        let data = { accessToken: response.accessToken, phone: phone }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        console.log('creating insta settings')

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/create_instagram_settings_mediakit', options)
            .then(result => result.json())
            .then(body => {
                console.log("SETTINGS CREATED: " + body);
                if (body["result"] == "Success") {
                    handleSubscribe(body["fbPageId"], body["pageAccessToken"])

                    // UNCOMMENT AFTER PERMISSION IS GRANTED

                    var res_username = body['username']
                    console.log('returned username');
                    console.log(res_username);

                    // var username = 'snitch.co.in'
                    // hit this
                    enrichMediaKitData(res_username)

                    // navigate(`/creatingInstaConnection/${phone}`)
                }
            }).catch((err) => {
                console.log('error');
                console.log(err);
                console.log(err.message);
                setInstagramLoginError(true)
                setInstagramLoginErrorMsg("Please connect a facebook page with your instagram account and try again.")
                setIsLoading(false);
            });


        // await fetch(process.env.REACT_APP_API_ADDRESS + '/api/create_instagram_settings_mediakit_temp', options)
        //     .then(result => result.json())
        //     .then(body => {
        //         console.log("SETTINGS CREATED: " + body);
        //         if (body["success"]) {
        //             setIsLoading(true)

        //             // UNCOMMENT AFTER PERMISSION IS GRANTED
        //             // handleSubscribe(body["fbPageId"], body["pageAccessToken"])


        //             // var res_username = body['username']
        //             // console.log('returned username');
        //             // console.log(res_username);

        //             // // var username = 'snitch.co.in'
        //             // // hit this
        //             // enrichMediaKitData(res_username)

        //             navigate(`/checkingInstaConnection/${phone}`)
        //         } else {
        //             console.log('error while saving instagram settings')
        //         }
        //     }).catch((err) => {
        //         console.log('error');
        //         console.log(err);
        //         setIsLoading(false);
        //     });
    }


    async function checkUsernameInput() {
        console.log('username check called')
        setUsernameInputLoading(true);

        // business discovery to check username, followers and profile pic - DONE
        // display that for verification and proceed, else input new username
        // redirect inside media kit for metrics input

        let data = {
            username: usernameInput,
            phoneNo: phone
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/check_manual_username_input_mediakit', options)
            .then(result => result.json())
            .then(body => {
                if (body["success"]) {
                    setFollowers(body['followers'])
                    setProfilePic(body['profilePic'])
                    setCaption(body['caption'])
                    setDisplayInfluencerDetails(true)
                }
                else {
                    setUsernameInputError(body["msg"])
                    setDisplayInfluencerDetails(true)
                }
            }).catch((err) => {
                console.log("ERROR")
                console.log(err);
                setIsLoading(false);
            });
    }

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(1) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(1) + "K";
        } else {
            return number;
        }
    }

    async function handleManualSubmit() {
        console.log('handle manual submit called')

        setSubmitButtonLoading(true)

        const data = {
            username: usernameInput,
            followers: followers,
            profilePic: profilePic,
            phoneNo: phone
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/update_manual_mediakit_influencer_details ', options)
            .then(result => result.json())
            .then(body => {
                if (body["success"]) {
                    console.log('success');
                    navigate(`/edit/${usernameInput}`);
                    setSubmitButtonLoading(false)
                }
                else {
                    setSubmitButtonLoading(false);
                    setSubmitButtonError(body["msg"]);
                }
            }).catch((err) => {
                console.log("ERROR")
                console.log(err);
                setIsLoading(false);
                setSubmitButtonLoading(false)
                setSubmitButtonError('Error');
            });
    }

    if (isLoading) {
        return (
            <Box><CircularProgress style={{ color: "black", }} size={50} /></Box>
        )
    } else {
        return (
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                {/* {isInstagramActive &&
                    <Button sx={{ border: "1px solid black", color: "black", width: "auto", height: "45px", gap: "10px" }} onClick={() => handleLogout()}>
                        <img
                            src={instagramLogo}
                            alt="instagram-icon"
                            border="0"
                            width="30px"
                            height="30px"
                        />
                        Connected
                    </Button>
                } */}
                {/* {
                    !isInstagramActive && */}
                <div className="content" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {/* 1869260720252577 */}
                    <FacebookLogin
                        size="large"
                        appId="338563195134203"
                        autoLoad={false}
                        disableMobileRedirect={true}
                        fields="name,email,picture"
                        scope="business_management,instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement,pages_manage_metadata,instagram_manage_comments, pages_messaging,instagram_manage_messages"
                        callback={responseFacebook}
                    />
                    {instagramLoginError &&
                        <Box sx={{ width: "80%", display: "flex", alignItems: "center", marginTop: "20px" }}>
                            {/* <Typography color={"#fff"}>{instagramLoginErrorMsg}</Typography> */}
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {instagramLoginErrorMsg}
                            </Alert>
                        </Box>
                    }
                </div>
                {/* } */}
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                    <Divider variant="middle" style={{ background: 'rgb(0,0,0,0.3)' }} sx={{ width: "30%", marginTop: "30px" }} />
                    <Typography sx={{ marginTop: "30px", color: "white" }}>OR</Typography>
                    <Divider variant="middle" style={{ background: 'rgb(0,0,0,0.3)' }} sx={{ width: "30%", marginTop: "30px" }} />
                </Box>
                <Box sx={{ display: "flex", width: "90%", justifyContent: "left", marginTop: "20px" }}>
                    <Typography color={'white'}>OPTION 2</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography color={"white"}>Enter Info Manually</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "left", flexDirection: "column", marginTop: "10px", width: "90%" }}>
                    <Typography color={'white'}>
                        Please Enter Your Instagram Username
                    </Typography>
                    <TextField
                        sx={{ width: "100%", background: "white", borderRadius: "5px", marginTop: "10px" }}
                        placeholder='Enter Username'
                        size="small"
                        value={usernameInput}
                        onChange={(e) => { setUsernameInput(e.target.value); setDisplayInfluencerDetails(false); setUsernameInputLoading(false); setUsernameInputError(""); setSubmitButtonError("") }}
                    />
                    {!displayInfluencerDetails && <LoadingButton loadingIndicator="Checking..." loading={usernameInputLoading} disabled={usernameInputLoading} onClick={() => checkUsernameInput()} sx={{ background: "white", color: "black", marginTop: "20px" }}>Check Username</LoadingButton>}
                    {displayInfluencerDetails && (usernameInputError == "") && <Grid container sx={{ marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
                            <Avatar sx={{ width: "50px", height: "50px" }} src={profilePic} />
                        </Grid>
                        <Grid item xs={8} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Typography color={'white'}>Username: {usernameInput}</Typography>
                            <Typography color={'white'}>Followers: {formatNumber(followers)}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "10px" }}>
                            <Typography color={'white'} sx={{ textAlign: "center" }}>If the above profile is correct, Please press submit</Typography>
                            {/* <Typography color={'white'} sx={{ textAlign: "center" }}>Else enter a new username</Typography> */}
                            <LoadingButton LoadingButton loadingIndicator="Creating MediaKit..." loading={submitButtonLoading} disabled={submitButtonLoading} onClick={() => handleManualSubmit()} sx={{ color: "white", background: "black", width: "60%", marginTop: "10px" }}>Submit</LoadingButton>
                            {!submitButtonLoading && (submitButtonError != "") && <Typography>{submitButtonError}</Typography>}
                        </Grid>
                    </Grid>}
                    {displayInfluencerDetails && (usernameInputError != "") && <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Typography color={'white'} sx={{ marginTop: "10px" }}>{usernameInputError}</Typography>
                        <Typography color={'white'}>Please enter the correct username</Typography>
                    </Box>}
                </Box>
            </Box>
        )
    }
}

export default InstagramLogin