import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CircularProgress, Divider, Grid, LinearProgress, TextField, Typography } from '@mui/material'
import PieCharts from '../common/PieCharts'
import { base_color_button } from '../common/global';

function AudienceDemographics(props) {

    // const audience = props.audience;
    const username = props.username;
    const isFacebookLogin = props.isFacebookLogin;

    // var audienceAge = audience.audienceAge
    // var audienceCity = audience.audienceCity
    // var audienceGender = audience.audienceGender

    const [audienceCity, setAudienceCity] = useState({});
    const [audienceAge, setAudienceAge] = useState({});
    const [audienceGender, setAudienceGender] = useState({});


    const [audienceCityLabels, setAudienceCityLabels] = useState([]);
    const [audienceCityDist, setAudienceCityDist] = useState([])
    const [newAudienceCity, setNewAudienceCity] = useState({});
    const [newAudienceGender, setNewAudienceGender] = useState({});
    const [newAudienceAge, setNewAudienceAge] = useState({});

    const [editCityDistribution, setEditCityDistribution] = useState(false);
    const [editGenderDistribution, setEditGenderDistribution] = useState(false);
    const [editAgeDistribution, setEditAgeDistribution] = useState(false);
    const [isAudienceLoading, setIsAudienceLoading] = useState(true);

    async function fetchAudienceDetails() {
        console.log('fetch audience details called')

        setIsAudienceLoading(true)

        let data = {
            username: username
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_mediakit_influencer_audience_details', options)
            .then(result => result.json())
            .then(body => {
                // console.log("body"); console.log(body);
                if (body['success']) {
                    setAudienceAge(body['audienceAge'])
                    setAudienceCity(body['audienceCity'])
                    setAudienceGender(body['audienceGender'])

                    setAudienceCityLabels(Object.keys(body['audienceCity']))
                    setAudienceCityDist(Object.values(body['audienceCity']))

                    setNewAudienceAge(body['audienceAge'])
                    setNewAudienceCity(body['audienceCity'])
                    setNewAudienceGender(body['audienceGender'])
                } else {
                    console.log('data not found')
                    setAudienceAge({})
                    setAudienceCity({})
                    setAudienceGender({})
                }
                setIsAudienceLoading(false)
            });
    }

    useEffect(() => {
        fetchAudienceDetails()
    }, [])

    // console.log('audience')
    // console.log(audienceCity)
    // console.log(audienceCityLabels)
    // console.log(audienceCityDist)
    // console.log(audienceAge)
    // console.log(audienceGender)

    // CITY
    // var totalCityCount = 100;
    // var cityDist = Object.values(audienceCity);
    // var cityLabels = Object.keys(audienceCity);
    // var finalCityDist = cityDist.map(element => (element / totalCityCount) * 100);
    const sortedCities = Object.entries(newAudienceCity)
        .sort((a, b) => b[1] - a[1]) // Sort the array by value in descending order
        .slice(0, 3); // Get the top three items

    // GENDER
    var genderDistribution = Object.values(newAudienceGender);
    var genderLabels = Object.keys(newAudienceGender);

    // AGE
    // var ageDistribution = Object.values(audienceAge).slice(0, 4);
    // var ageLabels = Object.keys(audienceAge).slice(0, 4);

    const sortedAges = Object.entries(newAudienceAge)
        .sort((a, b) => b[1] - a[1]) // Sort the array by value in descending order
        .slice(0, 2); // Get the top two items

    // Calculate the sum of the top two values
    const topTwoSum = sortedAges.reduce((acc, item) => acc + item[1], 0);

    // Calculate the "Others" category value
    var others = 0;
    if (topTwoSum != 0) {
        others = 100 - topTwoSum;
    }

    // Include "Others" in the results
    const finalSortedAges = [
        ...sortedAges,
        ["Others", others]
    ];

    var ageLabels = finalSortedAges.map(item => item[0]);
    var ageDistribution = finalSortedAges.map(item => item[1]);

    var genderDistributionSum = genderDistribution.reduce((a, b) => a + b, 0)
    var ageDistributionSum = ageDistribution.reduce((a, b) => a + b, 0)



    const handleEditCity = (value, type, index) => {
        setAudienceCityLabels((prevLabels) => {
            const updatedLabels = [...prevLabels];

            if (type === 1) {
                // Update the first city name (index 0)
                updatedLabels[index] = value;
            }

            // // Simultaneously update newAudienceCity
            // setNewAudienceCity((prevCity) => {
            //     const updatedCity = { ...prevCity };
            //     const currentDist = audienceCityDist[0];
            //     delete updatedCity[prevLabels[index]]; // Remove the old key
            //     updatedCity[updatedLabels[index]] = currentDist; // Add the new key with the old distribution
            //     return updatedCity;
            // });

            return updatedLabels;
        });

        setAudienceCityDist((prevDists) => {
            const updatedDists = [...prevDists];

            if (type === 0) {
                // Update the first city distribution (index 0)
                updatedDists[index] = parseInt(value) || 0;
            }

            // // Simultaneously update newAudienceCity
            // setNewAudienceCity((prevCity) => {
            //     const updatedCity = { ...prevCity };
            //     updatedCity[audienceCityLabels[index]] = updatedDists[index];
            //     return updatedCity;
            // });

            return updatedDists;
        });
    };

    const handleEditGender = (value, key) => {
        setNewAudienceGender((prev) => {
            const updatedGender = { ...prev };
            updatedGender[key] = parseInt(value) || 0; // Update the value for the corresponding gender key
            return updatedGender;
        });
    };

    const handleEditAge = (value, key) => {
        setNewAudienceAge((prev) => {
            const updatedAge = { ...prev };
            updatedAge[key] = parseInt(value) || 0; // Update the value for the corresponding gender key
            return updatedAge;
        });
    };

    async function saveAudienceData() {
        console.log('save called')

        console.log(audienceCityLabels)
        console.log(audienceCityDist)

        var updated_audience_city = {}

        audienceCityLabels.forEach((key, index) => {
            updated_audience_city[key] = audienceCityDist[index];
        });
        let data = {
            username: username,
            influencerData: {
                audienceAge: newAudienceAge,
                audienceCity: updated_audience_city,
                audienceGender: newAudienceGender
            }
        }

        console.log('data')
        console.log(data)

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                // 'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/update_mediakit_influencer_details', options)
            .then(result => result.json())
            .then(body => {
                console.log("body"); console.log(body);
                setEditGenderDistribution(false);
                setEditAgeDistribution(false);
                setEditCityDistribution(false);

                fetchAudienceDetails()
            });
    }

    function handleCancelCity() {
        setNewAudienceCity(audienceCity);
        setAudienceCityLabels(Object.keys(audienceCity));
        setAudienceCityDist(Object.values(audienceCity));
        setEditCityDistribution(false)
        fetchAudienceDetails()
    }


    function handleCancelAge() {
        setNewAudienceAge(audienceAge);
        setEditAgeDistribution(false)
        fetchAudienceDetails()
    }


    function handleCancelGender() {
        setNewAudienceGender(audienceGender);
        setEditGenderDistribution(false)
        fetchAudienceDetails()
    }

    if (isAudienceLoading) {
        return (
            <Box sx={{ background: "white", color: "black", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress style={{ color: "black", marginTop: "20px" }} size={50} />
            </Box>
        )
    } else {

        return (
            <Box sx={{ background: "white", color: "black", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ border: "1px solid black", padding: "5px 15px 5px 15px", margin: "20px 0px 10px 0px", borderRadius: "8px" }}>
                    <Typography>City Distribution</Typography>
                </Box>

                {editCityDistribution && (
                    <Grid container sx={{ display: "flex", justifyContent: "space-evenly" }}>
                        <Grid item xs={4}>
                            <Box sx={{ gap: "10px", background: "white", padding: "15px", marginBottom: "10px", height: "fit-content", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <TextField
                                    sx={{ fontWeight: "900", fontSize: "20px" }}
                                    label="City Dist."
                                    value={audienceCityDist[0]}
                                    onChange={(e) => handleEditCity(e.target.value, 0, 0)}
                                />
                                <TextField
                                    sx={{ fontWeight: "900", fontSize: "20px" }}
                                    label="City Name"
                                    value={audienceCityLabels[0]}
                                    onChange={(e) => handleEditCity(e.target.value, 1, 0)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ gap: "10px", background: "white", padding: "15px", marginBottom: "10px", height: "fit-content", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <TextField
                                    sx={{ fontWeight: "900", fontSize: "20px" }}
                                    label="City Dist."
                                    value={audienceCityDist[1]}
                                    onChange={(e) => handleEditCity(e.target.value, 0, 1)}
                                />
                                <TextField
                                    sx={{ fontWeight: "900", fontSize: "20px" }}
                                    label="City Name"
                                    value={audienceCityLabels[1]}
                                    onChange={(e) => handleEditCity(e.target.value, 1, 1)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ gap: "10px", background: "white", padding: "15px", marginBottom: "10px", height: "fit-content", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <TextField
                                    sx={{ fontWeight: "900", fontSize: "20px" }}
                                    label="City Dist."
                                    value={audienceCityDist[2]}
                                    onChange={(e) => handleEditCity(e.target.value, 0, 2)}
                                />
                                <TextField
                                    sx={{ fontWeight: "900", fontSize: "20px" }}
                                    label="City Name"
                                    value={audienceCityLabels[2]}
                                    onChange={(e) => handleEditCity(e.target.value, 1, 2)}
                                />
                            </Box>
                        </Grid>
                        {/* ))} */}
                    </Grid>
                )}

                <Grid container style={{ marginBottom: "20px", padding: "15px" }}>
                    {!editCityDistribution && sortedCities.map((value, index) => (
                        <Grid item xs={4} key={index} sx={{ display: "flex", justifyContent: "center", }}>
                            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", color: "black" }}>
                                <Typography variant='h4' sx={{ fontWeight: "900" }}>{value[1]}%</Typography>
                                <Typography>{value[0]}</Typography>
                            </Box>
                        </Grid>))}

                    {!editCityDistribution && !isFacebookLogin && <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}><Button onClick={() => setEditCityDistribution(true)} sx={{ background: base_color_button, color: "white", marginTop: "20px", width: "fit-content" }}>Edit City Dist.</Button></Box>}

                    {editCityDistribution &&
                        <Box sx={{ width: "100%", gap: "20px", display: "flex", justifyContent: "center" }}>
                            <Button sx={{ background: base_color_button, color: "white" }} onClick={() => saveAudienceData()}>Save</Button>
                            <Button sx={{ background: base_color_button, color: "white" }} onClick={() => handleCancelCity()}>Cancel</Button>
                        </Box>
                    }

                    <Grid item xs={12} sx={{ marginTop: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Box sx={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "center", width: "fit-content", border: "1px solid black", padding: "5px 15px 5px 15px", margin: "20px 0px 10px 0px", borderRadius: "8px" }}>
                            {/* <Typography variant="h6"></Typography> */}
                            <Typography>Gender Distribution</Typography>
                        </Box>
                        {editGenderDistribution && <Grid container sx={{ gap: "5px", display: "flex", justifyContent: "space-evenly" }}>
                            {Object.entries(newAudienceGender).map(([key, value]) => (
                                <Grid item xs={3} key={key}>
                                    {/* <Box sx={{ gap: "10px", background: "white", padding: "15px", marginBottom: "10px", height: "fit-content", display: "flex", flexDirection: "column", justifyContent: "space-between" }}> */}
                                    <TextField
                                        sx={{ fontWeight: "900", fontSize: "20px" }}
                                        // label="Gender Dist."
                                        value={value}
                                        onChange={(e) => handleEditGender(e.target.value, key)}
                                    />
                                    <Typography>{key}</Typography>
                                    {/* </Box> */}
                                </Grid>
                            ))}
                        </Grid>}

                        <Grid item style={{ width: "100%", height: "100%", }}>
                            {genderDistributionSum != 0 && <PieCharts labels={genderLabels} distribution={genderDistribution} type="gender" />}
                        </Grid>

                        {!editGenderDistribution && !isFacebookLogin && <Button onClick={() => setEditGenderDistribution(true)} sx={{ background: base_color_button, color: "white", marginTop: "20px", width: "fit-content" }}>Edit Gender Dist.</Button>}
                        {editGenderDistribution &&
                            <Box sx={{ gap: "20px", display: "flex", justifyContent: "center", marginTop: "20px" }}>
                                <Button sx={{ background: base_color_button, color: "white" }} onClick={() => saveAudienceData()}>Save</Button>
                                <Button sx={{ background: base_color_button, color: "white" }} onClick={() => handleCancelGender()}>Cancel</Button>
                            </Box>
                        }
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Box sx={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid black", padding: "5px 15px 5px 15px", margin: "20px 0px 10px 0px", borderRadius: "8px" }}>
                            <Typography>Age Distribution</Typography>
                        </Box>

                        {editAgeDistribution && <Grid container sx={{ gap: "5px", display: "flex", justifyContent: "space-evenly" }}>
                            {Object.entries(newAudienceAge).slice(0, 3).map(([key, value]) => (
                                <Grid item xs={3} key={key}>
                                    {/* <Box sx={{ gap: "10px", background: "white", padding: "15px", marginBottom: "10px", height: "fit-content", display: "flex", flexDirection: "column", justifyContent: "space-between" }}> */}
                                    <TextField
                                        sx={{ fontWeight: "900", fontSize: "20px" }}
                                        // label="Gender Dist."
                                        value={value}
                                        onChange={(e) => handleEditAge(e.target.value, key)}
                                    />
                                    <Typography>{key}</Typography>
                                    {/* </Box> */}
                                </Grid>
                            ))}
                        </Grid>}

                        <Grid item style={{ width: "100%", height: "100%", }}>
                            {ageDistributionSum != 0 && <PieCharts labels={ageLabels} distribution={ageDistribution} type="age" />}
                        </Grid>

                        {!editAgeDistribution && !isFacebookLogin && <Button sx={{ background: base_color_button, color: "white", marginTop: "20px", width: "fit-content" }} onClick={() => setEditAgeDistribution(true)}>Edit Age Dist.</Button>}
                        {editAgeDistribution &&
                            <Box sx={{ gap: "20px", display: "flex", justifyContent: "center" }}>
                                <Button sx={{ background: base_color_button, color: "white" }} onClick={() => saveAudienceData()}>Save</Button>
                                <Button sx={{ background: base_color_button, color: "white" }} onClick={() => handleCancelAge()}>Cancel</Button>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Box >
        )
    }
}

export default AudienceDemographics